import React from 'react'
import './rituwell.css'
import Link from "screens/home/components/link"
import Background from './assets/rituwell.png'
import Rituwell from './assets/rituwell-white.png'

const RituWell = () => {


    return (
        <div className='rituwell-container'>
            <p className='rituwell-intro'>Introducing RituWell, powered by JETSWEAT</p>
            <div className='rituwell-section'>
                <div className='rituwell-mid-section'> 
                    <img 
                        className='rituwell-white'
                        data-src={Rituwell}
                    />
                    <h1>
                        Empower Your Population to Live 
                        <br/> 
                        Their Healthiest Lives. 
                    </h1>
                    <Link
                        className="rituwell-demo-button"
                        label="Request a Demo"
                        href="/signup"
                    />

                </div>
                
                <img  
                    className='rituwell-background'
                    data-src={Background}
                />

            </div>
        </div>
    )
}

export default RituWell